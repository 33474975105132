.ExperiencePage-container {
    overflow: auto;
    min-height: 100%;
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    color: lightblue
}

.button-group-experience {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #050e2f; */
    z-index: 12;
    border: 2px solid lightgreen;
}

.button-group-experience button {
    border: none;
    flex: 1;
    color: lightblue;
    background-color: inherit;
    padding: 10px 10px;
    transition: all 0.3s ease;

}

.button-group-experience button:hover {
    border: none;
    color: lightgreen;
    /* background-color: #050e2f; */
}

.experience-page {
    /* background: linear-gradient(135deg, #0a192f 0%, #112240 100%); */
    min-height: 100vh;
    color: #e6f1ff;
    padding: 2rem 0 4rem;
    width: 100%;
}

.page-header {
    text-align: center;
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.page-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #64ffda;
    margin: 0;
    background: var(--bg-page-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-bar {
    width: 60px;
    height: 4px;
    background: var(--button-gradient);
    margin: 1rem auto;
    border-radius: 2px;
}

.header-description {
    color: #8892b0;
    font-size: 1.2rem;
    max-width: 800px;
    margin: 1.5rem auto 0;
    color: var(--project-text);
}

.filters-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
    background: inherit;
}

.category-buttons {
    gap: 1rem;
}

.company-filter span {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.category-button {
    background: var(--category-button-inactive);
    color: var(--category-text-inactive);
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    border: 1px solid var(--category-border);
    transition: all 0.3s ease;
}

.category-button:hover {
    background: var(--category-button-hover);
    color: var(--category-text-active);
    border: 1px solid var(--category-border);
}

.category-button.active {
    background: var(--category-button-active);
    color: var(--category-text-active);
}

.company-filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: inherit;
}

.company-filter span {
    white-space: nowrap;
}

.projects-grid {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    gap: 3rem;
    padding: 2rem 0;
    width: 95%;
    /* max-width: 1400px; */
    margin: 0 auto;
}

.project-card {
    flex: 1 1 400px;
    max-width: 450px;
    text-align: center;
    height: 600px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--project-card-bg);
    border-radius: 12px;
    border: 1px solid var(--project-card-border);
    box-shadow: var(--project-card-shadow);
    transition: all 0.3s ease;
    overflow: hidden;
}

.project-card:hover {
    background: var(--project-card-hover);
    box-shadow: var(--project-card-hover-shadow);
    transform: translateY(-5px);
}

.project-card img {
    width: 100%;
    height: 220px;
    object-fit: contain;
    border-radius: 12px 12px 0 0;
    transition: all 0.3s ease-in-out;
    padding: 1rem;
    background: linear-gradient(120deg, #123c48 0%, #166155 50%, #197662 100%);
}

.project-card:hover img {
    transform: scale(1.02);
}

.project-content {
    display: flex;
    flex-direction: column;
    height: calc(600px - 220px);
    padding: 1.5rem 1.5rem 2rem;
}

.project-content h3 {
    color: #ccd6f6;
    min-height: 70px;
    margin: 0;
    padding: 0.5rem 0;
    font-size: 1rem;
    transition: color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--project-title);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 80px;
}


.project-card:hover h3 {
    color: #64ffda;
}

.project-content p {
    color: #8892b0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-height: 120px; 
    margin: 0.5rem 0 auto;
    padding-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    flex-shrink: 0;
    color: var(--project-text);
}



.learn-more-button {
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    position: relative;
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.2rem;
    flex-shrink: 0;
}



.learn-more-button::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    padding: 2px;
    background: var(--button-gradient);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.learn-more-button:hover {
    background: rgba(100, 255, 218, 0.1);
    transform: translateY(-2px);
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    gap: 1rem;
}

.loading-container p {
    color: #8892b0;
    font-size: 1.1rem;
    margin: 0;
}

.loading-container .spinner-border {
    width: 3rem;
    height: 3rem;
    color: #64ffda;
}

.project-modal .modal-content {
    background: #0a192f;
    border: 1px solid rgba(100, 255, 218, 0.1);
}

.project-modal .modal-header {
    border-bottom: 1px solid rgba(100, 255, 218, 0.1);
    padding: 1.5rem;
}

.project-modal .modal-title {
    color: #64ffda;
    font-size: 1.8rem;
}

.close-button {
    background: transparent;
    color: var(--accent-primary);
    opacity: 0.8;
    transition: all 0.3s ease;
    border: 1px solid var(--accent-primary);
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
}

.close-button:hover {
    background: transparent;
    color: var(--accent-secondary);
    border-color: var(--accent-secondary);
    opacity: 1;
    transform: scale(1.1);
}


.company-dropdown .dropdown-toggle {
    background: var(--category-button-inactive);
    color: var(--category-text-inactive);
    border: 1px solid var(--category-border);
    transition: all 0.3s ease;
}

.company-dropdown .dropdown-toggle:hover {
    background: var(--category-hover-bg);
    color: var(--category-text-active);
    border: 1px solid var(--category-border);
}

.company-dropdown .dropdown-toggle[aria-expanded="true"] {
    background: var(--category-button-active);
    color: var(--category-text-active);
    border: 1px solid var(--category-border);
}

.company-dropdown .dropdown-menu {
    background: var(--dropdown-bg);
    border: 1px solid var(--category-border);
}

.company-dropdown .dropdown-item {
    color: var(--category-text-inactive);
    transition: all 0.3s ease;
}

.company-dropdown .dropdown-item:hover {
    background: var(--category-hover-bg);
    color: var(--category-text-active);
}

.company-dropdown .dropdown-item.active {
    background: var(--category-button-active);
    color: var(--category-text-active);
}



@media (max-width: 1200px) {
    .project-card {
        flex: 1 1 350px;
        height: 620px;
    }

    .project-content {
        height: calc(620px - 220px);
    }

    .project-content h3 {
        font-size: 1.6rem;
        min-height: 65px;
    }

    .project-content p {
        font-size: 1.1rem;
        min-height: 140px;
        -webkit-line-clamp: 5;
    }

    .learn-more-button {
        font-size: 1.1rem;
    }
}

@media (max-width: 768px) {
    /* .project-card {
        flex: 1 1 300px;
        height: 580px;
    } */

    .project-content {
        height: calc(580px - 220px);
    }

    .project-content h3 {
        font-size: 1.5rem;
        min-height: 60px;
    }

    .project-content p {
        font-size: 1rem;
        min-height: 120px;
        -webkit-line-clamp: 5;
    }

    .learn-more-button {
        font-size: 1rem;
    }

    .page-header h1 {
        font-size: 2rem;
    }

    .header-description {
        font-size: 1.1rem;
        padding: 0 1.5rem;
    }

    .projects-grid {
        grid-template-columns: minmax(300px, 350px);
    }

    .filters-section {
        gap: 1.5rem;
    }
}

@media (max-width: 576px) {
    .category-buttons {
        flex-direction: column;
        width: 80%;
        padding: 0 1rem;
    }

    .category-button {
        width: 100%;
    }

    .company-filter {
        width: 100%;
        padding: 0 1rem;
        justify-content: center;
    }

    .company-dropdown {
        width: auto;
        min-width: 150px;
    }

    .company-filter span {
        font-size: 1rem;
    }
}



@media (max-width: 480px) {
    .projects-grid {
        width: 90%;
    }
    
    .project-card {
        flex: 1 1 280px;
    }
}
