#navbar-wrapper {
  /* background-color: #050e2f; */
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */

}

.navbar-container {
    color: lightblue;
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
    font-size: medium;
    padding-left: 0px;
    /* background-color: #050e2f; */
}

.icon-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#toggle-bar  {
    color: lightblue;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='lightgreen' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler-icon:hover {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='lightblue' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0.5rem;
    margin-left: 1rem;
    z-index: 1000;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.navbar-toggler:active{
    border: none;
    outline: none;
    transform: none;
}

.navbar-name{
    font-size: larger;
    font-weight: bold;
}

.navbar-container a {
    color: lightblue;
}

.navbar-container a:hover {
    color: white;

}

#navbar-links a {
    padding-left: 17px;
}

#navbar-brand {
    padding-left: 20px;
}


.brand-container {
    position: relative;
    text-align: center;
}

.initials {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.hex-icon {
    scale: 2.3
}

.horizontalDivider {
    width: 30px;
    height: 3px;
    background-color: lightblue;
}

#nav-social {
    border-color: aqua;
    align-content: center;
    justify-content: center;
}

.modern-navbar {
    background: var(--navbar-bg);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid var(--navbar-border);
    transition: all 0.3s ease;
}

.modern-navbar.scrolled {
    background: var(--navbar-scrolled);
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
}

.monogram-letter {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.brand {
    padding: 0;
}

.logo {
    color: #64ffda;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: 2px solid #64ffda;
    border-radius: 4px;
}

.nav-links {
    gap: 1rem;
    align-items: center;
}

.nav-item {
    position: relative;
    margin: 0 0.5rem;
    padding: 0.5rem 0;
    color: var(--nav-text) !important;
    transition: color 0.3s ease !important;
}

.nav-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: var(--nav-item-underline);
    transition: all 0.3s ease;
    transform: translateX(-50%);
    opacity: 0;
}

.nav-item:hover {
    color: var(--nav-item-hover) !important;
}

.nav-item:hover::after {
    width: 100%;
    opacity: 1;
}

.nav-item.active {
    color: var(--nav-item-active) !important;
}

.nav-item.active::after {
    width: 100%;
    opacity: 1;
}

.nav-number {
    color: var(--nav-number);
    margin-right: 0.5rem;
    font-size: 0.9rem;
    font-family: 'SF Mono', monospace;
}

.nav-text {
    position: relative;
    display: inline-block;
    transition: color 0.3s ease;
}

.nav-item:hover .nav-text {
    color: var(--nav-text-hover);
}

.nav-item.active .nav-text {
    color: var(--nav-text-hover);
}

.resume-button-wrapper {
    margin-left: 1rem;
}

.resume-button {
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid transparent;
    position: relative;
    transition: all 0.3s ease;
}

.resume-button::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    padding: 2px;
    background: var(--button-gradient);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.resume-button:hover {
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    background: var(--button-hover-gradient);
    background-clip: padding-box;
    transform: translateY(-2px);
}

/* Hamburger Menu Styles */
.hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0.5rem;
}

.hamburger-lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
}

.hamburger-lines span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--hamburger-color);
    border-radius: 2px;
    transition: all 0.3s ease;
}

.hamburger-lines span + span {
    margin-top: 8px;
}

.hamburger-lines.open span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
}

.hamburger-lines.open span:nth-child(2) {
    opacity: 0;
}

.hamburger-lines.open span:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

.navbar-custom {
    background-color: var(--nav-bg);
    backdrop-filter: blur(10px);
    padding: 1rem 2rem;
    transition: all 0.3s ease;
    border-bottom: 1px solid var(--nav-border);
    box-shadow: var(--nav-shadow);
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.navbar .container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 2rem;
}




.navbar-brand {
    position: relative;
    text-decoration: none;
}

.brand-logo {
    position: relative;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--logo-border);
    border-radius: 12px;
    background: var(--logo-bg);
    box-shadow: 0 0 20px rgba(var(--accent-primary), 0.2);
    overflow: hidden;
}

.brand-logo::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #64ffda, #0a192f);
    z-index: -1;
    border-radius: 12px;
    opacity: 0.3;
}

.logo-text {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--logo-text);
    letter-spacing: 1px;
}

.nav-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.theme-toggle {
    background: transparent;
    border: none;
    color: var(--theme-toggle-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.theme-toggle:hover {
    background: var(--theme-toggle-hover);
    color: var(--theme-toggle-hover-color);
    transform: rotate(15deg);
}

.nav-link {
    color: var(--nav-text);
    margin: 0 1rem;
    padding: 0.5rem 0;
    font-size: 0.95rem;
    font-weight: 500;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.nav-link:hover,
.nav-link.active {
    color: var(--nav-hover);
}

.nav-link:hover .nav-text::after,
.nav-link.active .nav-text::after {
    width: 100%;
}

.resume-link {
    cursor: pointer;
    color: var(--nav-active);
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border: 1.5px solid var(--nav-active);
    border-radius: 6px;
    transition: all 0.2s ease;
}

.resume-link:hover {
    background: var(--resume-btn-hover);
    transform: translateY(-2px);
}


.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border: 2px solid var(--logo-border);
    border-radius: 8px;
    position: relative;
    background: transparent;
    transition: all 0.3s ease;
    transform-style: preserve-3d;
}

.logo-container:hover {
    box-shadow: 0 5px 15px var(--logo-hover-shadow);
}

.monogram {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'SF Mono', monospace;
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--button-gradient);
    letter-spacing: -1px;
    background: var(--button-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.monogram:hover {
    background: var(--button-hover-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.monogram-letter {
    position: relative;
    display: inline-block;
}

.monogram-letter:first-child {
    transform: translateX(2px);
}

.monogram-letter:last-child {
    transform: translateX(-2px);
}

.toggle-button-wrapper-navbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.nav-resume-button {
    background: transparent;
    border: 1px solid var(--accent-primary);
    color: var(--accent-primary);
    padding: 0.5rem 1.2rem;
    font-weight: 500;
    transition: all 0.3s ease;
    margin-left: 1rem;
}

.nav-resume-button:hover {
    color: var(--accent-secondary);
    border-color: var(--accent-secondary);
    transform: translateY(-2px);
    background: transparent;
}





/* Mobile Styles */
@media (max-width: 1200px) {
    .nav-links {
        text-align: center;
        padding: 1rem 0;
    }

    .toggle-button-wrapper-navbar {
        max-width: 20%;
    }

    .nav-item {
        padding: 0.5rem 0;
    }

    .hamburger {
        display: block;
    }

    .navbar-nav {
        padding: 1rem 0;
    }

    .resume-button-wrapper {
        margin: 1rem 0;
    }

    .navbar .container {
        padding: 0 1.5rem;
    }

    .navbar-custom {
        padding: 0.8rem 1rem;
    }

    .navbar .container {
        padding: 0 1rem;
    }

    .nav-link {
        margin: 0.5rem 0;
        padding: 0.5rem 1rem;
    }

    .nav-text::after {
        display: none;
    }

    .resume-link {
        margin: 0.5rem 1rem;
        text-align: center;
    }

    /* .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100vw;
        background: var(--navbar-bg);
        padding: 1rem;
        z-index: 999;
        border-bottom: 1px solid var(--navbar-border);
    } */

    .nav-controls {
        position: absolute;
        top: 1rem;
        right: 4rem;
        margin: 0;
    }
}

@media (max-width: 576px) {
    .logo {
        font-size: 1.5rem;
    }

    .nav-item {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {
    .nav-controls {
        right: 3.5rem;
    }

    .navbar .container {
        padding: 0 0.75rem;
    }
}

@media (min-width: 1200px) {
    .navbar-brand.brand {
        padding-left: 3rem;
    }

    .resume-button-wrapper {
        padding-right: 3rem;
    }
}

/* Add new media query for the range between xl and xxl breakpoints */
@media (min-width: 1200px) and (max-width: 1399px) {
    .nav-item {
        font-size: 0.9rem;
        margin: 0 0.3rem;  /* Reduce spacing between nav items */
    }

    .nav-number {
        font-size: 0.8rem;
    }

    .nav-resume-button {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
    }

    .nav-links {
        gap: 0.5rem;  /* Reduce gap between nav items */
    }
}


