.theme-toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
}

.theme-toggle {
    background: var(--theme-toggle-bg);
    color: var(--theme-toggle-color);
    border: 1px solid var(--theme-toggle-border);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.theme-toggle:hover {
    background: var(--theme-toggle-hover);
    color: var(--theme-toggle-hover-color);
    transform: rotate(15deg);
}

.theme-toggle svg {
    width: 20px;
    height: 20px;
}

.theme-toggle-button {
    position: relative;
    width: 64px;
    height: 36px;
    background: var(--theme-button-bg);
    border: 2px solid var(--theme-button-border);
    border-radius: 50px;
    padding: 0;
    cursor: pointer;
    overflow: visible;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px var(--theme-button-shadow);
}

.theme-toggle-button:hover {
    transform: scale(1.05);
    border-color: var(--theme-button-hover-border);
    box-shadow: 0 6px 16px var(--theme-button-shadow);
}

.slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    background: var(--slider-circle-bg);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.theme-toggle-button:hover .slider {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.moon-icon,
.sun-icon {
    position: absolute;
    width: 18px;
    height: 18px;
    transition: opacity 0.3s ease;
}

.moon-icon {
    opacity: 1;
    color: #000000;
    -webkit-text-stroke: 1px #e6f1ff;
}

.sun-icon {
    opacity: 0;
    color: #ffd700;
}

.theme-toggle-button.light .moon-icon {
    opacity: 0;
}

.theme-toggle-button.light .sun-icon {
    opacity: 1;
}

.theme-toggle-button.light .slider {
    transform: translateX(28px);
}

.theme-toggle-button:not(.light) .slider {
    background: var(--slider-circle-bg);
}

@media (max-width: 768px) {
    /* .theme-toggle-wrapper {
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } */
    
    .theme-toggle-button {
        width: 56px;
        height: 32px;
        margin: 0 8px;
    }
    
    .slider {
        width: 24px;
        height: 24px;
    }
    
    .theme-toggle-button.light .slider {
        transform: translateX(20px);
    }
    
    .moon-icon,
    .sun-icon {
        width: 14px;
        height: 14px;
    }
}

@media (max-width: 480px) {
    .theme-toggle-button {
        width: 48px;
        height: 28px;
        margin: 0 6px;
    }
    
    .slider {
        width: 20px;
        height: 20px;
        top: 2px;
        left: 2px;
    }
    
    .theme-toggle-button.light .slider {
        transform: translateX(5px);
    }
    
    .moon-icon,
    .sun-icon {
        width: 12px;
        height: 12px;
    }
} 